import React from "react";
import { styled } from "@mui/material/styles";
import { Box, Container, Typography } from "@mui/material";

const Root = styled(Box)(({ theme }) => ({
  "& h1": {
    maxWidth: "800px",
    fontSize: "60px",
    lineHeight: "1.2",
    fontWeight: 700,
    margin: "20px auto", // Aggiunge solo margine per lo spazio esterno
    textAlign: "center",
  },
  "& h2": {
    maxWidth: "700px",
    margin: "20px auto", // Aggiunge solo margine per lo spazio esterno
    fontSize: "46px",
    lineHeight: 1.3,
    fontWeight: 500,
    textAlign: "center",
  },
  "& h3": {
    maxWidth: "700px",
    margin: "20px auto", // Aggiunge solo margine per lo spazio esterno
    fontSize: "20px",
    lineHeight: 1.3,
    fontWeight: 400,
    textAlign: "center",
  },
  "& .hero-container": {
    paddingTop: "75px",
    paddingBottom: "75px",
    backgroundColor: "white",
    backgroundSize: "cover",
    color: theme.palette.primary.contrastText,
    //minHeight: "100vh",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    textAlign: "center",
  },
}));

const How_we_do_it = () => {
  return (
    <Root>
      <Container className="hero-container" maxWidth={false}>
        <Typography variant="h1" gutterBottom>
          How we do it?
        </Typography>
        <Typography variant="h3" gutterBottom>
          Weather forecasts generated and tailored for your business.
          Hyper-accurate forecasting starts here. By taking raw data from
          XXXXXXXX and interpolating with precise terrain and soil data, we are
          able to provide the most accurate weather dataset on the market, at an
          extremely high resolution. Integration with your existen company
          software Visualize all-important weather information - as many as 45
          key parameters. Get that crucial information to operate your business
          efficiently when you need it, where you need it. Insights!
        </Typography>
      </Container>
    </Root>
  );
};

export default How_we_do_it;
