import React from "react";
import { Typography } from "@mui/material";
import DefaultPageLayout from "../../components/DefaultPageLayout";

const Careers = () => {
  return (
    <>
      <DefaultPageLayout>
        <Typography variant="h1" gutterBottom>
          Careers
        </Typography>
      </DefaultPageLayout>
    </>
  );
};

export default Careers;
