import React, { useState } from "react";
import { Box, Container, Typography, Button, Modal } from "@mui/material";
import DefaultPageLayout from "../../components/DefaultPageLayout";
import ContactForm from "../../components/ContactForm";
import { styled } from "@mui/material/styles";

const Root = styled(Box)(({ theme }) => ({
  "& .form-container": {
    marginTop: "50px",
  },
}));

const Browser_dashboard = () => {
  // Aggiungi uno stato per il titolo del Modal
  const [open, setOpen] = useState(false);
  const [modalTitle, setModalTitle] = useState("");

  const handleOpen = (title) => {
    setModalTitle(title); // Imposta il titolo dinamicamente
    setOpen(true);
  };

  const handleClose = () => setOpen(false);

  return (
    <DefaultPageLayout>
      <Container maxWidth="lg" className="section">
        {/* Wrappa immagine e pulsante in un Box con flex-direction: column */}
        <Box display="flex" flexDirection="column" alignItems="center">
          {/* Prima immagine */}
          <img
            src="/images/dashboard.png"
            alt="Platform Screenshot 1"
            style={{ width: "70%", marginBottom: "20px" }}
          />

          <Typography variant="h3" gutterBottom>
            Our platform is a comprehensive tool designed to support drone
            operations in making critical weather decisions.
          </Typography>

          <Typography variant="h3" gutterBottom>
            We offer customizable domain setup and tuning to tailor our platform
            to your specific needs and requirements.
          </Typography>

          <Box className="form-container">
            <Typography variant="h2" gutterBottom>
              Request a Demo
            </Typography>
            <ContactForm />
          </Box>
        </Box>
      </Container>

      {/* Modal con il form di contatto e il titolo dinamico */}
      <Modal open={open} onClose={handleClose}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 500,
            bgcolor: "background.paper",
            border: "2px solid #000",
            boxShadow: 24,
            p: 4,
          }}
        >
          <Typography variant="h2" component="h2">
            {modalTitle} {/* Titolo dinamico */}
          </Typography>
          <ContactForm /> {/* Usa il ContactForm */}
        </Box>
      </Modal>
    </DefaultPageLayout>
  );
};

export default Browser_dashboard;
