import React from 'react';
import { Typography,  } from '@mui/material';
import ApiDocPage from './ApiDocPage';
import ApiDocEndpoint from './ApiDocEndpoint';

const MetarTafDataApi = () => { 

  return (
    <ApiDocPage title="Metar & TAF Data API">

      {/* Description */}
      <Typography variant="h2">
        Description
      </Typography>
      <Typography variant="paragraph">
      This API returns METARs and TAFs for airports worldwide. AIRMETs & SIGMETs in lavoration.
      Call by ICAO airport ID or area/bounding box.
      </Typography>

      {/* API */}
      <Typography variant="h2">
        Integration
      </Typography>
      <Typography variant="h3">
        Servers:
      </Typography>
      <Typography variant="paragraph">
        <table>
          <thead>
            <tr>
              <td>Environment</td>
              <td>URL</td>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Production</td>
              <td><code>https://weather-data.atmosoar.io/api</code></td>
            </tr>
            <tr>
              <td>Demo</td>
              <td><code>https://weather-data-dev2.atmosoar.io/api</code></td>
            </tr>
          </tbody>
        </table>
      </Typography>
      <Typography variant="h3">
        Endpoints:
      </Typography>
      <Typography variant="h4">
        Metar:
      </Typography>
      <ApiDocEndpoint 
        summary="Get METAR data for the specified airport"
        method="GET"
        url="/metar/station/{icao}"
        parameters={[
          {name:'icao',       type:'string', format:null,           required:true,  description:'ICAO code of the airport'},
        ]}
        responses={[
          {code:'200',  description:'Successful Response', contentType:'application/json'},
          {code:'404',  description:'Not Found - Data Not Available', contentType:'application/json'},
        ]}
      />
      <ApiDocEndpoint 
        summary="Get METAR data for the specified area"
        method="GET"
        url="/metar/area"
        parameters={[
          {name:'north',    type:'number', format:'float',        required:true,  description:'North latitude of the area'},
          {name:'south',    type:'number', format:'float',        required:true,  description:'South latitude of the area'},
          {name:'east',     type:'number', format:'float',        required:true,  description:'East longitude of the area'},
          {name:'west',     type:'number', format:'float',        required:true,  description:'West longitude of the area'},
        ]}
        responses={[
          {code:'200',  description:'Successful Response', contentType:'application/json'},
          {code:'404',  description:'Not Found - Data Not Available', contentType:'application/json'},
        ]}
      />
      <Typography variant="h4">
        TAF:
      </Typography>
      <ApiDocEndpoint 
        summary="Get TAF data for the specified airport"
        method="GET"
        url="/taf/station/{icao}"
        parameters={[
          {name:'icao',       type:'string', format:null,           required:true,  description:'ICAO code of the airport'},
        ]}
        responses={[
            {code:'200',  description:'Successful Response', contentType:'application/json'},
            {code:'400',  description:'Bad Request - Invalid parameters', contentType:'application/json'},
            {code:'404',  description:'Not Found - Data Not Available', contentType:'application/json'},
            {code:'500',  description:'Internal Server Error', contentType:'application/json'},
        ]}
      />
      <ApiDocEndpoint 
        summary="Get TAF data for the specified area"
        method="GET"
        url="/taf/area"
        parameters={[
          {name:'north',    type:'number', format:'float',        required:true,  description:'North latitude of the area'},
          {name:'south',    type:'number', format:'float',        required:true,  description:'South latitude of the area'},
          {name:'east',     type:'number', format:'float',        required:true,  description:'East longitude of the area'},
          {name:'west',     type:'number', format:'float',        required:true,  description:'West longitude of the area'},
        ]}
        responses={[
          {code:'200',  description:'Successful Response', contentType:'application/json'},
          {code:'404',  description:'Not Found - Data Not Available', contentType:'application/json'},
        ]}
      />
      <Typography variant="h4">
        Metar + TAF:
      </Typography>
      <ApiDocEndpoint 
        summary="Get METAR + TAF data for the specified area"
        method="GET"
        url="/metar-taf/area"
        parameters={[
          {name:'north',    type:'number', format:'float',        required:true,  description:'North latitude of the area'},
          {name:'south',    type:'number', format:'float',        required:true,  description:'South latitude of the area'},
          {name:'east',     type:'number', format:'float',        required:true,  description:'East longitude of the area'},
          {name:'west',     type:'number', format:'float',        required:true,  description:'West longitude of the area'},
        ]}
        responses={[
            {code:'200',  description:'Successful Response', contentType:'application/json'},
            {code:'400',  description:'Bad Request - Invalid parameters', contentType:'application/json'},
            {code:'404',  description:'Not Found - Data Not Available', contentType:'application/json'},
            {code:'500',  description:'Internal Server Error', contentType:'application/json'},
        ]}
      />

    </ApiDocPage>
  );
};

export default MetarTafDataApi;