// src/App.js
import React from "react";

import { Routes, Route } from "react-router-dom";
import usePageTracking from "./components/usePageTracking";

import Home from "./pages/Home/Home";
import ApiPage from "./pages/Home/Api_page";
import Browser_dashboard from "./pages/Home/Browser_dashboard";
import HowWeDoIt from "./pages/Home/How_we_do_it";
import Solutions from "./pages/Home/Solutions";
import Observation_network from "./pages/Home/Observation_network";
import Uav from "./pages/Home/Uav";
import Ren_energy from "./pages/Home/Ren_energy";
import Defense from "./pages/Home/Defense";
import Surveillance from "./pages/Home/Surveillance";
import Agriculture from "./pages/Home/Agriculture";
import Transportation from "./pages/Home/Transportation";
import Insurance from "./pages/Home/Insurance";
import SolutionsSummary from "./pages/Home/SolutionsSummary";
import ProductsSummary from "./pages/Home/ProductsSummary";

import Bio from "./pages/Home/Bio";
import Team from "./pages/Home/Team";
import Careers from "./pages/Home/Careers";

import WeatherForecastDataApi from "./pages/ApiDocumentation/WeatherForecastDataApi";
import MetarTafDataApi from "./pages/ApiDocumentation/MetarTafDataApi";
import MissionSafetyAnalysisApi from "./pages/ApiDocumentation/MissionSafetyAnalysisApi";
import ElevationApi from "./pages/ApiDocumentation/ElevationApi";

function App() {
  usePageTracking(); // Usa l'hook per tracciare le pageviews
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/products-summary" element={<ProductsSummary />} />
      <Route path="/solutions-summary" element={<SolutionsSummary />} />
      <Route path="/apis" element={<ApiPage />} />
      <Route
        path="/apis/weather-forecast-data"
        element={<WeatherForecastDataApi />}
      />
      <Route path="/apis/metar-taf-data" element={<MetarTafDataApi />} />
      <Route
        path="/apis/mission-safety-analysis"
        element={<MissionSafetyAnalysisApi />}
      />
      <Route path="/apis/elevation" element={<ElevationApi />} />
      <Route path="/Browser_dashboard" element={<Browser_dashboard />} />
      <Route path="/How_we_do_it" element={<HowWeDoIt />} />
      <Route path="/Browser_dashboard" element={<Browser_dashboard />} />
      <Route path="/Solutions" element={<Solutions />} />
      <Route path="/Observation_network" element={<Observation_network />} />
      <Route path="/Uav" element={<Uav />} />
      <Route path="/Ren_energy" element={<Ren_energy />} />
      <Route path="/Defense" element={<Defense />} />
      <Route path="/Surveillance" element={<Surveillance />} />
      <Route path="/Agriculture" element={<Agriculture />} />
      <Route path="/Transportation" element={<Transportation />} />
      <Route path="/Insurance" element={<Insurance />} />

      <Route path="/Bio" element={<Bio />} />
      <Route path="/Team" element={<Team />} />
      <Route path="/Careers" element={<Careers />} />
    </Routes>
  );
}

export default App;
