import React from "react";
import SolutionsPageLayout from "../../components/SolutionsPageLayout";
import DefaultPageLayout from "../../components/DefaultPageLayout";

const content = [
  "Fluctuations in wind speed, solar irradiance, and precipitation can significantly impact the output of wind farms, solar power plants, and hydroelectric facilities. Our accurate weather forecasts can help you optimize your operations and maximize energy production.",
  "Key Benefits:",
  [
    "Predict weather conditions and adjust operations to maximize output from wind farms, solar power plants, and hydroelectric facilities.",
    "Provide accurate forecasts to grid operators, enabling them to balance supply and demand and maintain grid stability.",
    "Optimize maintenance and repair schedules based on weather forecasts, reducing downtime and costs. ",
    "Anticipate extreme weather events and take proactive measures to protect your assets and minimize disruptions. ",
  ],
];

const Ren_energy = () => {
  return (
    <DefaultPageLayout>
      <SolutionsPageLayout
        title="Renewable Energy"
        content={content}
        imageUrl="/images/ren_energy.jpeg"
      />
    </DefaultPageLayout>
  );
};

export default Ren_energy;
