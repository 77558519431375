import React from "react";
import {
  Container,
  Typography,
  Card,
  CardContent,
  CardActions,
  Button,
} from "@mui/material";
import { Link } from "react-router-dom";
import DefaultPageLayout from "../../components/DefaultPageLayout";
import { styled } from "@mui/material/styles";

const Root = styled("div")(({ theme }) => ({
  "& .card-container": {
    marginTop: "20px",
    marginBottom: "20px",
  },
  "& .card": {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    marginBottom: "20px", // Spazio tra le card
  },
  "& .card-content": {
    flexGrow: 1,
  },
  "& .card-content > .card-title": {
    color: theme.palette.primary.dark,
    marginTop: "0px",
  },
  "& .card-content > .card-description": {
    color: theme.palette.primary.dark,
  },
  "& .card-actions": {
    padding: "16px",
  },
  "& .card-actions > .card-action": {
    color: theme.palette.primary.dark,
    backgroundColor: theme.palette.primary.light,
    paddingLeft: "10px",
    paddingRight: "10px",
  },
}));

const ProductsSummary = () => {
  return (
    <DefaultPageLayout>
      <Root>
        <Container>
          <Typography variant="h1" gutterBottom>
            Products Overview
          </Typography>

          {/* Browser Dashboard */}
          <Card className="card">
            <CardContent className="card-content">
              <Typography variant="h5" component="div" className="card-title">
                <strong>Browser Dashboard</strong>
              </Typography>
              <Typography variant="body2" className="card-description">
                Our platform is a comprehensive tool designed to support drone
                operations in making critical weather decisions. It’s a
                weather-centric mission planner that helps you optimize flight
                paths, avoiding areas with adverse weather conditions.
              </Typography>
            </CardContent>
            <CardActions className="card-actions">
              <Button
                component={Link}
                to="/Browser_dashboard"
                size="small"
                className="card-action"
              >
                Learn More
              </Button>
            </CardActions>
          </Card>

          {/* APIs */}
          <Card className="card">
            <CardContent className="card-content">
              <Typography variant="h5" component="div" className="card-title">
                <strong>APIs</strong>
              </Typography>
              <Typography variant="body2" className="card-description">
                Our API documentation is available at Atmosoar's official page,
                with detailed descriptions of each API and their respective
                links to the documentation.
              </Typography>
            </CardContent>
            <CardActions className="card-actions">
              <Button
                component="a"
                href="https://docs.atmosoar.io/"
                target="_blank"
                rel="noopener noreferrer"
                size="small"
                className="card-action"
              >
                Learn More
              </Button>
            </CardActions>
          </Card>

          {/* Observation Network */}
          <Card className="card">
            <CardContent className="card-content">
              <Typography variant="h5" component="div" className="card-title">
                <strong>Observation Network</strong>
              </Typography>
              <Typography variant="body2" className="card-description">
                We can help you design and install a high-quality weather
                station system equipped with precision instruments and sensors.
                Already have a network? We can seamlessly integrate your
                existing stations.
              </Typography>
            </CardContent>
            <CardActions className="card-actions">
              <Button
                component={Link}
                to="/Observation_network"
                size="small"
                className="card-action"
              >
                Learn More
              </Button>
            </CardActions>
          </Card>
        </Container>
      </Root>
    </DefaultPageLayout>
  );
};

export default ProductsSummary;
