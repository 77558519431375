import React, { useState } from "react";
import { styled } from "@mui/material/styles";
import { Box, Container, Typography, Button, Modal } from "@mui/material";
import ContactForm from "../../components/ContactForm"; // Importa il ContactForm dal percorso corretto
import DefaultPageLayout from "../../components/DefaultPageLayout";

const Home = () => {
  // Aggiungi uno stato per il titolo del Modal
  const [open, setOpen] = useState(false);
  const [modalTitle, setModalTitle] = useState("");

  const handleOpen = (title) => {
    setModalTitle(title); // Imposta il titolo dinamicamente
    setOpen(true);
  };

  const handleClose = () => setOpen(false);

  return (
    <DefaultPageLayout>
      <Container className="hero-container" maxWidth={false}>
        <Typography variant="h1" gutterBottom>
          Atmosoar
        </Typography>
        <Typography variant="h2" gutterBottom>
          Your Business, Weather-Proofed.
        </Typography>
        <Typography variant="h3">
          Hyper-accurate forecasts, tailored to your specific needs. From raw
          data to actionable insights, we've got your business covered.
        </Typography>
        <div className="hero-buttons">
          <Button
            variant="contained"
            color="primary"
            className="button"
            href="https://docs.atmosoar.io/"
            target="_blank"
            rel="noopener noreferrer"
          >
            Try our weather API
          </Button>
          <Button
            variant="outlined"
            color="primary"
            onClick={() => handleOpen("Request a demo")}
          >
            Get demo
          </Button>
        </div>
      </Container>

      <Container className="side-picture-container">
        <img
          src="/images/home1.jpeg"
          alt="Illustrative"
          className="side-picture"
        />
        <div>
          <Typography variant="h2">
            Weather, Your Business's Biggest Risk.
          </Typography>
          <Typography variant="h3">
            Climate change is intensifying weather events. Traditional forecasts
            are falling short. Protect your business with our hyper-accurate
            weather data and actionable insights. Build resilience, stay ahead
            of the curve.
          </Typography>
          {/* Aggiorna il pulsante "Learn More" per cambiare il titolo del Modal */}
          <Button
            variant="outlined"
            color="primary"
            onClick={() => handleOpen("Contact Us")}
          >
            Learn More About Our Solutions
          </Button>
        </div>
      </Container>

      {/* How we do it section */}
      <Typography variant="h1">How we do it?</Typography>
      <Container className="side-picture-container">
        <div>
          <Typography variant="h3">
            <strong>Forecasts:</strong> We refine raw weather data using our
            proprietary algorithms, incorporating hyper-local terrain and soil
            information for high accuracy. The resulting dataset powers our
            advanced forecasting models, delivering highly precise predictions
            tailored to your specific location and needs.
          </Typography>
        </div>
        <img
          src="/images/how_we.jpg"
          alt="Illustrative"
          className="side-picture"
        />
      </Container>

      {/* insights */}
      <Container className="side-picture-container">
        <img
          src="/images/insights.jpg"
          alt="Illustrative"
          className="side-picture"
        />
        <div>
          <Typography variant="h3" gutterBottom>
            <strong>Insights:</strong> We don’t just provide forecasts; we
            deliver actionable insights. By analyzing our data, we can help you
            anticipate extreme weather events, optimize operations, and make
            informed decisions that enhance profitability. With our insights,
            you'll be better equipped to navigate the challenges and
            opportunities presented by weather.
          </Typography>
        </div>
      </Container>

      {/* Detailed Data section */}
      <Typography variant="h1" className="section-title">
        Data
      </Typography>

      <Container className="side-picture-container">
        <div>
          <Typography variant="h3">
            <strong>Core Meteorological Parameters:</strong> Temperature,
            atmospheric pressure, dew point, relative humidity, rainfall and
            snowfall, freezing level, wind speed and direction, wind gusts,
            visibility, and more.
          </Typography>
          <Typography variant="h3">
            <strong>Aviation-Specific Data:</strong> Density altitude, QNH,
            icing conditions, and other critical aviation parameters.
          </Typography>
          <Typography variant="h3">
            <strong>Operational Insights:</strong> GNSS jamming/spoofing, space
            weather (KP index, Aurora), airspace information, and population
            density data.
          </Typography>
          <Typography variant="h3">
            <strong>Global Airport Coverage:</strong> METAR, TAF and SIGMET
            reports for airports worldwide.
          </Typography>
        </div>
        <img
          src="/images/data.jpeg"
          alt="Illustrative"
          className="side-picture"
        />
      </Container>
      <Typography variant="h3">
        Do you need others?{" "}
        <Button
          variant="text"
          color="primary"
          onClick={() => handleOpen("Contact Us")}
        >
          Contact us!
        </Button>
      </Typography>

      {/* Seamless Integration */}
      <Container className="section">
        <Typography variant="h2" className="section-title">
          Seamless Integration
        </Typography>
        <Typography variant="h3">
          We offer custom solutions tailored to your specific needs, ensuring
          that our weather data aligns perfectly with your business objectives.
          Our service is designed to integrate seamlessly with your existing
          systems, ensuring effortless access to critical weather information.
          You can easily incorporate our data into your workflows, making
          informed decisions based on real-time weather updates.
        </Typography>
      </Container>

      {/* Products section */}
      <Container className="product-section">
        <Typography variant="h2" className="section-title">
          Products
        </Typography>

        <div className="product-item">
          <Typography variant="h3">
            <strong>Platform:</strong> Increase safety, compliance, and
            efficiency of your drone operations. Decision support tools to help
            you manage weather threats, especially for BVLOS operations.
          </Typography>

          {/* Cambia il titolo per "Request a Demo" */}
          <Button
            variant="contained"
            color="primary"
            onClick={() => handleOpen("Request a Demo")}
          >
            See how it works
          </Button>
        </div>

        <div className="product-item">
          <Typography variant="h3">
            <strong>APIs:</strong> Access our weather data through our robust
            APIs.
          </Typography>
          <Button
            variant="outlined"
            color="primary"
            href="https://docs.atmosoar.io/"
            target="_blank"
            rel="noopener noreferrer"
          >
            Try our weather API
          </Button>
        </div>

        <div className="product-item">
          <Typography variant="h3">
            <strong>Observation Network:</strong> Certified weather station
            installation and wide-area observation networks. Make use of the
            weather and be sure you have the information you need to make
            informed decisions.
          </Typography>
          <Button
            variant="outlined"
            color="primary"
            onClick={() => handleOpen("Contact Us")}
          >
            Contact Us
          </Button>
        </div>
      </Container>

      {/* Modal con il form di contatto e il titolo dinamico */}
      <Modal open={open} onClose={handleClose}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 500,
            bgcolor: "background.paper",
            border: "2px solid #000",
            boxShadow: 24,
            p: 4,
          }}
        >
          <Typography variant="h2" component="h2">
            {modalTitle} {/* Titolo dinamico */}
          </Typography>
          <ContactForm /> {/* Usa il ContactForm */}
        </Box>
      </Modal>
    </DefaultPageLayout>
  );
};

export default Home;
