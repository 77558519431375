import React from "react";
import SolutionsPageLayout from "../../components/SolutionsPageLayout";
import DefaultPageLayout from "../../components/DefaultPageLayout";

const content = [
  "Adverse weather conditions can lead to delays, cancellations, and even safety hazards. Our hyper-accurate weather forecasts can help you optimize your operations and minimize risks.",
  "Key Benefits:",
  [
    "Avoid hazardous weather conditions, reducing the risk of accidents and cargo loss.",
    "Optimize flight planning, reduce delays, and increase on-time performance.",
    "Minimize operational costs associated with weather-related disruptions and make informed decisions based on real-time weather data, improving your overall operational efficiency.",
  ],
];

const UAV = () => {
  return (
    <DefaultPageLayout>
      <SolutionsPageLayout
        title="UAV Operations and Aviation"
        content={content}
        imageUrl="/images/uav.jpg"
      />
    </DefaultPageLayout>
  );
};

export default UAV;
