import React, { useState, useRef } from "react";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";

export default function DropdownMenu({ buttonLabel, menuItems }) {
  const [anchorEl, setAnchorEl] = useState(null);
  const [menuOpen, setMenuOpen] = useState(false);
  const timeoutRef = useRef(null);

  const handleMouseEnterButton = (event) => {
    clearTimeout(timeoutRef.current);
    setAnchorEl(event.currentTarget);
    setMenuOpen(true); // Mantieni il menu aperto
  };

  const handleMouseLeaveButton = (event) => {
    timeoutRef.current = setTimeout(() => {
      if (!menuOpen) {
        if (!event.currentTarget.contains(event.relatedTarget)) {
          setAnchorEl(null);
        }
      }
    }, 100); // Tempo breve per chiudere il menu se non viene attivato
  };

  const handleMouseEnterMenu = () => {
    clearTimeout(timeoutRef.current);
  };

  const handleMouseLeaveMenu = () => {
    timeoutRef.current = setTimeout(() => {
      setAnchorEl(null);
    }, 100);
  };

  const isMenuOpen = Boolean(anchorEl);

  return (
    <div style={{ display: "inline-block" }}>
      <Button
        color="secondary"
        id="basic-button"
        aria-controls={isMenuOpen ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={isMenuOpen ? "true" : undefined}
        onMouseEnter={handleMouseEnterButton}
        onMouseLeave={handleMouseLeaveButton}
        sx={{ fontWeight: 400, fontSize: "16px", flexGrow: 1 }}
      >
        {buttonLabel}
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={isMenuOpen}
        onClose={handleMouseLeaveMenu}
        MenuListProps={{
          "aria-labelledby": "basic-button",
          onMouseEnter: handleMouseEnterMenu,
          onMouseLeave: handleMouseLeaveMenu,
        }}
        disableAutoFocusItem
      >
        {menuItems.map((item, index) =>
          item.external ? (
            // Se è un link esterno, usa <a> con target _blank
            <MenuItem
              key={index}
              onClick={handleMouseLeaveMenu}
              component="a"
              href={item.href}
              target="_blank"
              rel="noopener noreferrer"
            >
              {item.label}
            </MenuItem>
          ) : (
            // Se è un link interno, resta come prima
            <MenuItem
              key={index}
              onClick={handleMouseLeaveMenu}
              component="a"
              href={item.link}
            >
              {item.label}
            </MenuItem>
          )
        )}
      </Menu>
    </div>
  );
}
