import React from "react";
import SolutionsPageLayout from "../../components/SolutionsPageLayout";
import DefaultPageLayout from "../../components/DefaultPageLayout";

const content = [
  "Weather is a major factor in transportation, affecting everything from road conditions and flight operations to shipping schedules and traffic flow. Our hyper-accurate weather forecasts can provide essential information for optimizing transportation planning, mitigating risks, and ensuring efficient and safe operations.",
  "Key Benefits:",
  [
    "Optimize routes to avoid adverse weather conditions, reducing travel time and fuel consumption.",
    "Anticipate traffic congestion due to weather-related incidents and implement effective traffic management strategies.",
    "Minimize disruptions to flights, trains, and shipping schedules due to adverse weather.",
    "Improve road safety by providing real-time information on weather-related hazards such as fog, ice, and heavy rain.",
    "Receive critical weather information during emergencies to support disaster response and recovery efforts.",
  ],
];

const Transportation = () => {
  return (
    <DefaultPageLayout>
      <SolutionsPageLayout
        title="Transportation"
        content={content}
        imageUrl="/images/transportation.jpeg" // Replace with correct image path
      />
    </DefaultPageLayout>
  );
};

export default Transportation;
