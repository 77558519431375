import React from "react";
import { Box, Container, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import ContactForm from "../components/ContactForm";

const Root = styled(Box)(({ theme }) => ({
  padding: "50px 0",
  "& .content-container": {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
    },
  },
  "& .text-container": {
    flex: 1,
    marginRight: "20px",
    [theme.breakpoints.down("sm")]: {
      marginRight: "0px",
      marginBottom: "20px",
    },
  },
  "& .image-container": {
    flex: 1,
  },
  "& img": {
    width: "100%",
    height: "auto",
  },
  "& .form-container": {
    marginTop: "50px",
  },
  "& h1": {
    fontSize: "48px", // Dimensione normale per schermi più grandi
    [theme.breakpoints.down("sm")]: {
      fontSize: "32px", // Ridimensiona il titolo per dispositivi mobili
    },
  },
}));

const SolutionsPageLayout = ({ title, content, imageUrl }) => {
  return (
    <Root>
      <Container>
        <Typography variant="h1" gutterBottom>
          {title}
        </Typography>
        <Box className="content-container">
          <Box className="text-container">
            {/* Usa Typography per ogni paragrafo */}
            <Typography variant="h4" gutterBottom>
              {content[0]}
            </Typography>
            <Typography variant="h5" gutterBottom>
              {content[1]}
            </Typography>
            <ul>
              {content[2].map((item, index) => (
                <li key={index}>
                  <Typography variant="body1">{item}</Typography>
                </li>
              ))}
            </ul>
          </Box>
          <Box className="image-container">
            <img src={imageUrl} alt={title} />
          </Box>
        </Box>
        <Box className="form-container">
          <Typography variant="h2" gutterBottom>
            Request a Demo
          </Typography>
          <ContactForm />
        </Box>
      </Container>
    </Root>
  );
};

export default SolutionsPageLayout;
