import React from "react";
import SolutionsPageLayout from "../../components/SolutionsPageLayout";
import DefaultPageLayout from "../../components/DefaultPageLayout";

const content = [
  "Our hyper-accurate weather forecasts can provide essential information for optimizing sensor placement, predicting visibility conditions, and ensuring the reliability of surveillance systems. ",
  "Key Benefits:",
  [
    "Optimize sensor placement and adjust surveillance parameters based on weather conditions to maximize detection capabilities. ",
    "Anticipate changes in visibility due to fog, haze, or precipitation, enabling you to adjust surveillance operations accordingly. ",
    "Minimize false alarms caused by adverse weather conditions, improving the accuracy and reliability of your surveillance systems. ",
    "Identify potential threats and vulnerabilities based on weather conditions, enabling you to take proactive measures to protect critical assets. ",
  ],
];

const Surveillance = () => {
  return (
    <DefaultPageLayout>
      <SolutionsPageLayout
        title="Surveillance and monitoring"
        content={content}
        imageUrl="/image.png"
      />
    </DefaultPageLayout>
  );
};

export default Surveillance;
