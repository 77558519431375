import React from "react";
import SolutionsPageLayout from "../../components/SolutionsPageLayout";
import DefaultPageLayout from "../../components/DefaultPageLayout";

const content = [
  "Weather is a major risk factor for insurance companies, leading to significant losses due to property damage, business interruption, and personal injury. Our hyper-accurate weather forecasts can help insurers assess risk, underwrite policies more effectively, and manage claims more efficiently.",
  "Key Benefits:",
  [
    "Accurately assess the risk of weather-related losses for individual properties, businesses, experiences or events.",
    "Anticipate potential claims and deploy resources proactively to manage claims more efficiently.",
    "Develop customized loss models to assess the financial impact of potential weather events.",
  ],
];

const Insurance = () => {
  return (
    <DefaultPageLayout>
      <SolutionsPageLayout
        title="Insurance"
        content={content}
        imageUrl="/image.png" // Replace with correct image path
      />
    </DefaultPageLayout>
  );
};

export default Insurance;
