import React, { useState } from "react";
import { Box, Container, Typography, Button, Modal } from "@mui/material";
import DefaultPageLayout from "../../components/DefaultPageLayout";
import ContactForm from "../../components/ContactForm";

const Observation_network = () => {
  // Aggiungi uno stato per il titolo del Modal
  const [open, setOpen] = useState(false);
  const [modalTitle, setModalTitle] = useState("");

  const handleOpen = (title) => {
    setModalTitle(title); // Imposta il titolo dinamicamente
    setOpen(true);
  };

  const handleClose = () => setOpen(false);

  return (
    <DefaultPageLayout>
      <Container maxWidth="xl" className="section">
        <Container className="section-title">
          <Typography variant="h1" gutterBottom>
            Observation Network
          </Typography>
        </Container>
        {/* Aggiungi screenshot */}
        <Box
          component="img"
          src="/Weather_station.png"
          alt="Weather station"
          sx={{
            maxHeight: "300px",
            width: "auto", // Imposta la larghezza automatica
            maxWidth: "100%", // Impedisce di superare la larghezza del contenitore
            objectFit: "contain", // Mantiene le proporzioni dell'immagine
            marginBottom: "20px",
          }}
        />

        <Typography variant="h3" gutterBottom>
          Need a customized weather station? We can help you design and install
          a high-quality system equipped with precision instruments and sensors
          that meet international standards. Our weather stations provide
          real-time data on a wide range of meteorological parameters, ensuring
          you have the information you need to make informed decisions.
        </Typography>

        <Typography variant="h3" gutterBottom>
          Already have weather stations? No problem. We can seamlessly integrate
          your existing network into our system, providing a unified platform
          for accessing and analyzing weather data.
        </Typography>

        <Button
          variant="contained"
          color="primary"
          style={{ margin: "40px 0" }}
          onClick={() => handleOpen("Contact Us")}
        >
          Request a Demo
        </Button>
      </Container>

      {/* Modal con il form di contatto e il titolo dinamico */}
      <Modal open={open} onClose={handleClose}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 500,
            bgcolor: "background.paper",
            border: "2px solid #000",
            boxShadow: 24,
            p: 4,
          }}
        >
          <Typography variant="h2" component="h2">
            {modalTitle} {/* Titolo dinamico */}
          </Typography>
          <ContactForm /> {/* Usa il ContactForm */}
        </Box>
      </Modal>
    </DefaultPageLayout>
  );
};

export default Observation_network;
