// src/index.js
import React from "react";
import ReactDOM from "react-dom/client";
import "bootstrap/dist/css/bootstrap.min.css";
import "typeface-lato";
import "typeface-source-code-pro";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { createTheme, ThemeProvider } from "@mui/material";
import ReactGA from "react-ga4";
import { BrowserRouter as Router } from "react-router-dom"; // Sposta il Router qui

import theme from "./theme.js";

// Inizializza Google Analytics con il tuo Measurement ID
ReactGA.initialize("G-9HYTC1FRW9"); // Sostituisci con il tuo Measurement ID

ReactGA.send("pageview"); // Traccia una pageview iniziale

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <Router>
      <ThemeProvider theme={theme}>
        <App />
      </ThemeProvider>
    </Router>
  </React.StrictMode>
);

// Se vuoi misurare le prestazioni, usa reportWebVitals
reportWebVitals();
