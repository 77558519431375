import { useTheme, styled } from "@mui/material/styles";
import { Box, Typography, Container } from "@mui/material";
import NavigationBar from "./DefaultNavigationBar";

const Root = styled(Box)(({ theme }) => ({
  width: "100%",
  minHeight: "100vh", // Assicura che il contenuto occupi tutta l'altezza della pagina
  display: "flex",
  flexDirection: "column",

  "& h1, & h2, & h3": {
    maxWidth: "800px",
    margin: "20px auto",
    textAlign: "center",
  },
  "& h1": {
    fontSize: "60px",
    lineHeight: "1.2",
    fontWeight: 700,
  },
  "& h2": {
    fontSize: "42px",
    lineHeight: 1.3,
    fontWeight: 500,
  },
  "& h3": {
    fontSize: "20px",
    lineHeight: 1.3,
    fontWeight: 400,
  },
  "& .hero-container": {
    paddingTop: "75px",
    paddingBottom: "75px",
    backgroundColor: "white",
    color: theme.palette.primary.contrastText,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    textAlign: "center",
  },
  "& .hero-buttons": {
    marginTop: "40px",
    display: "flex",
    gap: "20px",
    "& .button": {
      padding: "10px 20px",
      fontSize: "16px",
    },
  },
  "& .side-picture-container": {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginTop: "30px",
    marginBottom: "80px",
    maxWidth: "900px",
    textAlign: "center",
    flexDirection: "row", // Per schermi più grandi, affianca l'immagine e il testo
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column", // Su mobile, metti l'immagine e il testo uno sotto l'altro
    },
  },
  "& .side-picture": {
    width: "100%",
    maxWidth: "400px",
    height: "auto",
    margin: "0 20px",
    [theme.breakpoints.down("sm")]: {
      marginRight: 0, // Rimuovi il margine destro su mobile
      marginBottom: "20px", // Aggiungi uno spazio sotto l'immagine su mobile
    },
  },
  "& .section": {
    marginTop: "30px",
    textAlign: "center",
    backgroundColor: theme.palette.background.paper,
    flexDirection: "column",
  },
  "& .section-title": {
    marginTop: "60px",
    fontSize: "40px",
    fontWeight: 600,
  },
  "& .product-section": {
    marginTop: "60px",
    backgroundColor: "#f8f9fa",
    padding: "50px 0",
  },
  "& .product-item": {
    textAlign: "center",
    margin: "20px 0",
  },
}));

const Content = styled(Box)(({ theme }) => ({
  flex: 1, // Si espande per occupare lo spazio disponibile
}));

const Footer = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main, // Stesso colore dell'AppBar
  color: theme.palette.primary.contrastText, // Colore del testo in contrasto
  padding: theme.spacing(2), // Padding per dare spazio interno
  textAlign: "center",
}));

const DefaultPageLayout = ({ children }) => {
  const theme = useTheme();

  return (
    <Root theme={theme}>
      <NavigationBar />
      <Content>{children}</Content>
      <Footer>
        <Container>
          <Typography variant="body2" color="inherit" fontSize={"18px"}>
            © 2024 Atmosoar - Atmospheric Soaring Technologies
          </Typography>
        </Container>
      </Footer>
    </Root>
  );
};

export default DefaultPageLayout;
