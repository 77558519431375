import React from "react";
import SolutionsPageLayout from "../../components/SolutionsPageLayout";
import DefaultPageLayout from "../../components/DefaultPageLayout";

const content = [
  "Weather is a critical factor in agricultural production, affecting everything from crop growth and yield to livestock health and productivity. Our hyper-accurate weather forecasts can provide essential information for optimizing farming practices, mitigating risks, and ensuring successful harvests.",
  "Key Benefits:",
  [
    "Make informed decisions about planting, irrigation, fertilization, and pest control based on weather forecasts. Plus, use weather data to predict the risk of crop diseases and take preventive measures.",
    "Maximize crop yields by avoiding adverse weather conditions and optimizing farming practices. Track soil moisture levels to optimize irrigation and prevent water stress.",
    "Minimize losses due to extreme weather events such as droughts, floods, and frost.",
    "Monitor weather conditions to protect livestock from heat stress, cold stress, and disease.",
  ],
];

const Agriculture = () => {
  return (
    <DefaultPageLayout>
      <SolutionsPageLayout
        title="Agriculture"
        content={content}
        imageUrl="/images/agricolture.jpeg" // Replace with correct image path
      />
    </DefaultPageLayout>
  );
};

export default Agriculture;
