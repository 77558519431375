import { styled } from "@mui/material/styles";
import { Box, Container, Typography } from "@mui/material";
import PageLayout from "../../components/DefaultPageLayout";

const Root = styled(Box)(({ theme }) => ({
  "& .page-container": {
    paddingTop: "45px",
    paddingBottom: "45px",
    minHeight: "100vh", // Assicura che il contenuto occupi tutta l'altezza della pagina
  },
  "& table": {
    borderWidth: "2px",
    borderStyle: "solid",
    borderColor: theme.palette.common.white,
    borderCollapse: "collapse",
  },
  "& table > thead > tr > td": {
    borderWidth: "2px",
    borderStyle: "solid",
    borderColor: theme.palette.common.white,
    backgroundColor: theme.palette.primary.light,
    padding: "5px 10px",
    fontWeight: 600,
  },
  "& table > tbody > tr > td": {
    borderWidth: "2px",
    borderStyle: "solid",
    borderColor: theme.palette.common.white,
    backgroundColor: theme.palette.primary.light,
    padding: "5px 10px",
    fontWeight: 400,
  },
  "& table > tbody > tr:nth-child(odd) > td": {
    backgroundColor: theme.palette.primary.lightTableStripe,
  },
}));

const ApiDocPage = ({ title, children }) => {
  return (
    <Root>
      <Container className="page-container">
        <Typography variant="h1">{title}</Typography>
        {children}
      </Container>
    </Root>
  );
};

export default ApiDocPage;
