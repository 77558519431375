import React from "react";
import SolutionsPageLayout from "../../components/SolutionsPageLayout";
import DefaultPageLayout from "../../components/DefaultPageLayout";

const content = [
  "Weather is a critical factor in military operations, affecting everything from troop deployment and logistics to combat effectiveness. Our hyper-accurate weather forecasts can provide essential information for planning and executing missions, ensuring operational success and the safety of personnel. ",
  "Key Benefits:",
  [
    "Make informed decisions about troop deployment, equipment selection, and mission timing based on accurate weather forecasts. ",
    "Optimize supply chains and transportation routes to avoid weather-related disruptions. ",
    "Anticipate extreme weather events and take proactive measures to protect personnel and equipment. ",
    "Our stations are designed for deployment in remote and challenging environments, ensuring uninterrupted weather data collection even in off-grid locations.",
  ],
];

const Defence = () => {
  return (
    <DefaultPageLayout>
      <SolutionsPageLayout
        title="Defense"
        content={content}
        imageUrl="/images/defense.jpeg"
      />
    </DefaultPageLayout>
  );
};

export default Defence;
