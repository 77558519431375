import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import Container from "@mui/material/Container";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
import MenuItem from "@mui/material/MenuItem";
import DropdownMenu from "./DropdownMenu";
import SolutionsSummary from "../pages/Home/SolutionsSummary";
import { useNavigate } from "react-router-dom";

import Modal from "@mui/material/Modal"; // Importa il Modal
import ContactForm from "./ContactForm"; // Importa il ContactForm

const pages = ["Products", "Solutions"];
const settings = ["Profile", "Account", "Logout"];

function ResponsiveAppBar() {
  const navigate = useNavigate(); // Usa useNavigate per fare il redirect

  const PRODUCTS = [
    { label: "Dashboard", link: "/Browser_dashboard" },
    {
      label: "Weather APIs",
      external: true, // Indica che è un link esterno
      href: "https://docs.atmosoar.io/", // Link esterno
    },
    { label: "Observation Network", link: "/Observation_network" },
  ];
  const SOLUTIONS = [
    { label: "Overview", link: "/solutions-summary" }, // Link alla pagina riassuntiva
    { label: "UAV operations and aviation", link: "/Uav" },
    { label: "Renewable Energy", link: "/Ren_energy" },
    { label: "Defense", link: "/Defense" },
    { label: "Agriculture", link: "/Agriculture" },
    { label: "Transportation", link: "/Transportation" },
    { label: "Insurance", link: "/Insurance" },
  ];

  /*
  const ABOUTUS = [
    { label: "COMPANY BIO", link: "/Bio" },
    { label: "TEAM", link: "/Team" },
    { label: "CAREERS", link: "/Careers" },
  ];
*/
  const handleNavigate = (page) => {
    if (page === "Products") {
      navigate("/products-summary"); // Redirect alla pagina riassuntiva dei prodotti
    } else if (page === "Solutions") {
      navigate("/solutions-summary"); // Redirect alla pagina riassuntiva delle soluzioni
    }
  };

  // Stato per il Menu
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);

  // Stato per il Modal e il titolo dinamico
  const [open, setOpen] = React.useState(false);
  const [modalTitle, setModalTitle] = React.useState("");

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };
  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  // Funzione per aprire il Modal e impostare il titolo
  const handleOpenModal = (title) => {
    setModalTitle(title);
    setOpen(true);
  };

  const handleCloseModal = () => setOpen(false);

  return (
    <AppBar position="static">
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          {/*-----------------  LOGO DESKTOP -----------------*/}
          <Box sx={{ display: { xs: "none", md: "block" } }}>
            <img
              src="/favicon.png"
              alt="Logo"
              style={{ height: "50px" }}
              sx={{ mr: 1 }}
            />
          </Box>
          <Typography
            variant="h6"
            noWrap
            component="a"
            href="/"
            sx={{
              mr: 2,
              ml: 2,
              display: { xs: "none", md: "flex" },
              fontWeight: 400,
              color: "white",
              textDecoration: "none",
            }}
          >
            ATMOSOAR
          </Typography>

          {/* -----------------  MENU MOBILE ----------------- */}
          <Box
            sx={{
              flexGrow: 1,
              alignItems: "center",
              justifyContent: "space-between",
              display: { xs: "flex", md: "none" },
            }}
          >
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{ display: { xs: "block", md: "none" } }}
            >
              {pages.map((page) => (
                <MenuItem
                  key={page}
                  onClick={() => {
                    handleCloseNavMenu();
                    handleNavigate(page); // Gestisce la navigazione
                  }}
                >
                  <Typography sx={{ textAlign: "center" }}>{page}</Typography>
                </MenuItem>
              ))}
            </Menu>
          </Box>

          {/*-----------------  LOGO MOBILE -----------------*/}

          <Box sx={{ display: { xs: "block", md: "none" } }}>
            <img
              src="/favicon.png"
              alt="Logo"
              style={{ height: "50px" }}
              sx={{ mr: 1 }}
            />
          </Box>
          <Typography
            variant="h6"
            noWrap
            component="a"
            href="/"
            sx={{
              mr: 2,
              ml: 2,
              flexGrow: 1,
              fontWeight: 400,
              color: "white",
              textDecoration: "none",
              display: { xs: "block", md: "none" },
            }}
          >
            ATMOSOAR
          </Typography>

          {/*-----------------  MENU DESKTOP -----------------*/}

          <Box
            sx={{
              flexGrow: 1,
              display: { xs: "none", md: "flex" },
              justifyContent: "space-between", // Distribuisce i blocchi con spazio equo
              alignItems: "center",
              ml: 2,
              mr: 2,
            }}
          >
            {/* Colonna per il pulsante PRODUCTS */}
            <Box
              sx={{ flexGrow: 1, display: "flex", justifyContent: "center" }}
            >
              <DropdownMenu buttonLabel="PRODUCTS" menuItems={PRODUCTS} />
            </Box>

            {/* Colonna per il pulsante SOLUTIONS */}
            <Box
              sx={{ flexGrow: 1, display: "flex", justifyContent: "center" }}
            >
              <DropdownMenu buttonLabel="SOLUTIONS" menuItems={SOLUTIONS} />
            </Box>

            {/* Colonna per il pulsante GET DEMO */}
            <Box
              sx={{ flexGrow: 1, display: "flex", justifyContent: "center" }}
            >
              <Button
                variant="contained"
                onClick={() => handleOpenModal("Request a Demo")}
              >
                GET DEMO
              </Button>
            </Box>
          </Box>
          {/*-----------------  USER SETTINGS ( menu a destra) -----------------*/}
          {/*

          <Box sx={{ flexGrow: 0 }}>
            <Tooltip title="Open settings">
              <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                <Avatar alt="Remy Sharp" src="/static/images/avatar/2.jpg" />
              </IconButton>
            </Tooltip>
            <Menu
              sx={{ mt: "45px" }}
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
            >
              {settings.map((setting) => (
                <MenuItem key={setting} onClick={handleCloseUserMenu}>
                  <Typography sx={{ textAlign: "center" }}>
                    {setting}
                  </Typography>
                </MenuItem>
              ))}
            </Menu>
          </Box>
          */}
        </Toolbar>
      </Container>

      {/* Modal con il form di contatto */}
      <Modal open={open} onClose={handleCloseModal}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 500,
            bgcolor: "background.paper",
            border: "2px solid #000",
            boxShadow: 24,
            p: 4,
          }}
        >
          <Typography variant="h2" component="h2">
            {modalTitle} {/* Titolo dinamico */}
          </Typography>
          <ContactForm /> {/* Usa il ContactForm */}
        </Box>
      </Modal>
    </AppBar>
  );
}
export default ResponsiveAppBar;
