import React from 'react';
import { Typography } from '@mui/material';
import ApiDocPage from './ApiDocPage';
import ApiDocEndpoint from './ApiDocEndpoint';

const MissionSafetyAnalysisApi = () => {
  return (
    <ApiDocPage title="Mission Weather Impact Safety Analysis API">

      {/* Description */}
      <Typography variant="h2">
        Description
      </Typography>
      <Typography variant="body1">
        This API provides a programmatic way to assess weather risks during drone missions. It takes into account weather data, flight plans, and drone limitations to deliver an objective analysis. Users can specify the weather model and define both the mission’s details and the route waypoints. The response includes a detailed weather report, including an overall impact level (green, amber, red) for the entire mission and for each potential risk factor (wind, rain, temperature).
      </Typography>

      {/* API */}
      <Typography variant="h2">
        Integration
      </Typography>
      <Typography variant="h3">
        Servers:
      </Typography>
      <Typography variant="body1">
        <table>
          <thead>
            <tr>
              <td>Environment</td>
              <td>URL</td>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Production</td>
              <td><code>https://europe-west1-weather-data-api.cloudfunctions.net</code></td>
            </tr>
          </tbody>
        </table>
      </Typography>
      <Typography variant="h3">
        Endpoint:
      </Typography>
       
      <ApiDocEndpoint 
        summary="Get report for the specified coordinate"
        method="POST"
        url="/missionSafetyAnalysis-v1"
        parameters={[
          {name: 'key', type: 'string', format: null, required: true, description: 'API key for authentication'}
        ]}
        requestBody={{
          contentType: 'application/json',
          example: JSON.stringify({
            "weatherModel": "metw-uk-7",
            "takeoffDatetime": "2023-09-21T19:26:46.084Z",
            "phases": [
              {
                "tag": "cruise",
                "speed": 20,
                "speedType": "tas",
                "maxWind": 15,
                "maxRain": 20,
                "minAirTemperature": 273.15,
                "maxAirTemperature": 318.15,
                "warningThresholdPercent": 0.75,
                "batteryConsumptionFactor": 1,
                "batteryConsumptionFactorHovering": 2
              }
            ],
            "waypoints": [
              {
                "latitude": 52.274119376193,
                "longitude": -3.3398742148437,
                "altitude": 120,
                "phase": "cruise",
                "hoveringDuration": 0
              },
              {
                "latitude": 52.201795056648,
                "longitude": -3.0267638632812,
                "altitude": 120,
                "phase": "cruise",
                "hoveringDuration": 0
              }
            ],
            "maxWaypointsDistance": 500
          }, null, 2),
          schema: {
            type: 'object',
            properties: {
              weatherModel: { type: 'string', description: 'Weather model to use for analysis', required: true },
              takeoffDatetime: { type: 'string', format: 'date-time', description: 'Takeoff date and time in ISO format', required: true },
              phases: {
                type: 'array',
                required: true,
                items: {
                  type: 'object',
                  properties: {
                    tag: { type: 'string', description: 'Phase tag (e.g., cruise)', required: true },
                    speed: { type: 'number', description: 'Speed during the phase', required: true },
                    speedType: { type: 'string', description: 'Type of speed (GPS or TAS)', required: true },
                    maxWind: { type: 'number', description: 'Maximum wind speed', required: false },
                    maxRain: { type: 'number', description: 'Maximum rain', required: false },
                    minAirTemperature: { type: 'number', description: 'Minimum air temperature in Kelvin', required: false },
                    maxAirTemperature: { type: 'number', description: 'Maximum air temperature in Kelvin', required: false },
                    warningThresholdPercent: { type: 'number', description: 'Warning threshold percentage', required: false },
                    batteryConsumptionFactor: { type: 'number', description: 'Battery consumption factor', required: false },
                    batteryConsumptionFactorHovering: { type: 'number', description: 'Battery consumption factor during hovering', required: false }
                  },
                  required: ['tag', 'speed', 'speedType']
                }
              },
              waypoints: {
                type: 'array',
                required: true,
                items: {
                  type: 'object',
                  properties: {
                    latitude: { type: 'number', format: 'float', description: 'Latitude of the waypoint', required: true },
                    longitude: { type: 'number', format: 'float', description: 'Longitude of the waypoint', required: true },
                    altitude: { type: 'number', description: 'Altitude of the waypoint', required: true },
                    phase: { type: 'string', description: 'Flight phase at the waypoint', required: true },
                    hoveringDuration: { type: 'number', description: 'Hovering duration at the waypoint', required: false }
                  },
                  required: ['latitude', 'longitude', 'altitude', 'phase']
                }
              },
              maxWaypointsDistance: { type: 'number', description: 'Maximum distance between waypoints', required: false }
            }
          }
        }}
        responses={[
          {code:'200', description:'Successful Response', contentType:'application/json'},
          {code:'400', description:'Bad Request - Invalid parameters', contentType:'application/json'},
          {code:'404', description:'Not Found - Data Not Available', contentType:'application/json'},
          {code:'500', description:'Internal Server Error', contentType:'application/json'},
        ]}
      />

    </ApiDocPage>
  );
};

export default MissionSafetyAnalysisApi;
