import React from "react";
import { Typography } from "@mui/material";

const Solutions = () => {
  return (
    <>
      <Typography variant="h1" gutterBottom>
        Solutions
      </Typography>
    </>
  );
};

export default Solutions;
