import React from "react";
import {
  Container,
  Typography,
  Card,
  CardContent,
  CardActions,
  Button,
} from "@mui/material";
import { Link } from "react-router-dom";
import DefaultPageLayout from "../../components/DefaultPageLayout";
import { styled } from "@mui/material/styles";

const Root = styled("div")(({ theme }) => ({
  "& .card-container": {
    marginTop: "20px",
    marginBottom: "20px",
  },
  "& .card": {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    marginBottom: "20px", // Spazio tra le card
  },
  "& .card-content": {
    flexGrow: 1,
  },
  "& .card-content > .card-title": {
    color: theme.palette.primary.dark,
    marginTop: "0px",
  },
  "& .card-content > .card-description": {
    color: theme.palette.primary.dark,
  },
  "& .card-actions": {
    padding: "16px",
  },
  "& .card-actions > .card-action": {
    color: theme.palette.primary.dark,
    backgroundColor: theme.palette.primary.light,
    paddingLeft: "10px",
    paddingRight: "10px",
  },
}));
const solutions = [
  {
    title: "UAV Operations and Aviation",
    description:
      "Adverse weather conditions can lead to delays, cancellations, and even safety hazards. Our hyper-accurate weather forecasts can help you optimize your operations and minimize risks.",
    link: "/Uav",
  },
  {
    title: "Renewable Energy",
    description:
      "Fluctuations in wind speed, solar irradiance, and precipitation can significantly impact the output of wind farms, solar power plants, and hydroelectric facilities. Our accurate weather forecasts can help you optimize your operations and maximize energy production.",
    link: "/Ren_energy",
  },
  {
    title: "Army and Defense",
    description:
      "Weather is a critical factor in military operations, affecting everything from troop deployment and logistics to combat effectiveness. Our hyper-accurate weather forecasts can provide essential information for planning and executing missions.",
    link: "/Defense",
  },
  {
    title: "Surveillance and Monitoring",
    description:
      "Our hyper-accurate weather forecasts can provide essential information for optimizing sensor placement, predicting visibility conditions, and ensuring the reliability of surveillance systems.",
    link: "/Surveillance",
  },
  {
    title: "Agriculture",
    description:
      "Weather is a critical factor in agricultural production. Our hyper-accurate weather forecasts can provide essential information for optimizing farming practices and ensuring successful harvests.",
    link: "/Agriculture",
  },
  {
    title: "Transportation",
    description:
      "Weather affects road conditions, flight operations, and shipping schedules. Our hyper-accurate weather forecasts provide essential information for optimizing transportation planning and mitigating risks.",
    link: "/Transportation",
  },
  {
    title: "Insurance",
    description:
      "Weather is a major risk factor for insurance companies. Our hyper-accurate weather forecasts can help insurers assess risk and manage claims more efficiently.",
    link: "/Insurance",
  },
];

const SolutionsSummary = () => {
  return (
    <DefaultPageLayout>
      <Root>
        <Container>
          <Typography variant="h1" gutterBottom>
            Solutions Overview
          </Typography>

          {solutions.map((solution, index) => (
            <Card className="card" key={index}>
              <CardContent className="card-content">
                <Typography variant="h5" component="div" className="card-title">
                  <strong>{solution.title}</strong>
                </Typography>
                <Typography variant="body2" className="card-description">
                  {solution.description}
                </Typography>
              </CardContent>
              <CardActions className="card-actions">
                <Button
                  component={Link}
                  to={solution.link}
                  size="small"
                  className="card-action"
                >
                  Learn More
                </Button>
              </CardActions>
            </Card>
          ))}
        </Container>
      </Root>
    </DefaultPageLayout>
  );
};

export default SolutionsSummary;
