import React from "react";
import { useForm, ValidationError } from "@formspree/react";
import { Box, Button, TextField, Typography } from "@mui/material";
import ReactGA from "react-ga4"; // Importa ReactGA per il tracking

function ContactForm() {
  const [state, handleSubmit] = useForm("xldrkvvg"); // Usa il tuo form ID di Formspree

  const handleFormSubmit = async (event) => {
    // Invoca l'handleSubmit originale
    await handleSubmit(event);

    if (state.succeeded) {
      // Traccia l'evento di invio form in Google Analytics
      ReactGA.event({
        category: "Contact",
        action: "Submitted Contact Form",
        label: "Contact Us",
      });
    }
  };

  if (state.succeeded) {
    return (
      <Typography variant="h3">
        Thank you for your request! We will get back to you soon.
      </Typography>
    );
  }

  return (
    <Box
      component="form"
      onSubmit={handleFormSubmit} // Usa la nuova funzione handleFormSubmit
      sx={{ maxWidth: 600, margin: "auto", padding: "20px" }}
    >
      {/* Campo Nome */}
      <TextField
        fullWidth
        id="name"
        label="Name"
        name="name"
        type="text"
        margin="normal"
        required
      />
      <ValidationError prefix="Name" field="name" errors={state.errors} />

      {/* Campo Business Email */}
      <TextField
        fullWidth
        id="email"
        label="Email"
        name="email"
        type="email"
        margin="normal"
        required
      />
      <ValidationError prefix="Email" field="email" errors={state.errors} />

      {/* Bottone di invio */}
      <Button
        variant="contained"
        color="primary"
        type="submit"
        fullWidth
        disabled={state.submitting}
        sx={{
          display: "block",
          margin: "20px auto 0 auto",
        }}
      >
        Request a Demo
      </Button>
    </Box>
  );
}

export default ContactForm;
